<!--Author: zjy   
	Date(该日期为最后修改日期): 2020.12.22
	Description:首页之新闻动态模块
	History: 暂无
-->
<template>
  <div id="section1_box">
    <div class="section">
      <div class="newsTrends">
        <h2 :class="anmititle">新闻动态</h2>
        <p style="font-size: 16px" class="newcontentintro" :class="anmititle">
          即时更新各类型相关行业资讯，最快了解行业相关政策
        </p>

        <div class="News_Info">
          <div
            class="Single_News"
            v-for="(item, index) in newsTrends"
            :class="index%2==1 ?anmi:anmi1"
            :key="index"
            @click="toNewsDetail(index)"
          >
            <div class="snewsbox">
              <img
              :src="imgUrlFront + item.CoverImgUrl"
              @error="defImg($event, index)"
              />
            </div>
            <div class="news_right">
              <h4>{{ item.ArticleTitle }}</h4>
              <p>{{ item.Summary }}</p>
            </div>
          </div>
        </div>
        <p class="moreProduct" @click="tonews">更多新闻></p>
        <img
          class="newsbottom_banner"
          src="~/assets/images/home/advertising-sip-3.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeSection1",
  data() {
    return {
      imgUrlFront: "", //图片前缀url
      newsTrends: [], //新闻动态
      pageSize: 8, //请求数量
      pageNo: 1, //页码数
      anmititle:'', //首页新闻标题动画
      anmi:'',//首页新闻动画
      anmi1:'',//首页新闻动画1
    };
  },
  created() {
   
    this.imgUrlFront = window.sessionStorage.getItem("apiImgUrl");
    //请求首页新闻接口
    let requestParams = {
      Status: 3,
      IsShowIndex: "1",
      ArticleTypeID: "",
      PageIndex: this.pageNo,
      PageSize: this.pageSize,
    };
    this.$axios("API/Get_Article", requestParams)
      .then((res) => {
        let newResDate = [];
        //对返回数组图片进行切割取第一张图片
        res.data.some((item) => {
          item.CoverImgUrl = decodeURIComponent(item.CoverImgUrl).split(",", 1);
          item.Summary = decodeURIComponent(item.Summary);
          item.ArticleTitle = decodeURIComponent(item.ArticleTitle);
          item.iserror = false;
          newResDate.push(item);
        });
        //赋值至页面
        this.newsTrends = newResDate;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  mounted(){
    // window.addEventListener('scroll',this.scrollheight,true) //创建滚动监听事件
  },
  methods: {
    scrollheight(){
      const offset = this.$el.getBoundingClientRect();
      const offsetTop = offset.top;
      const offsetBottom = offset.bottom;

      if (offsetTop <= window.innerHeight && offsetBottom >= 0) {//到达新闻可视区域-触发新闻到达可视区域的动画
        this.anmititle = 'animate__animated animate__fadeInDown animate__slower'
        this.anmi = 'animate__animated animate__fadeInRight animate__slower'
        this.anmi1 = 'animate__animated animate__fadeInLeft animate__slower'
      }
    },
    tonews() {
      this.$router.push({
        path: "/news",
      });
    },
    //路由跳转至新闻详情页
    toNewsDetail(index) {
	  let flnkid = this.newsTrends[index].FLnkID
      this.$router.push({
        name: "newsdetail",
        query: {
          newsflnkid: flnkid,
		  
        },
      });
    },
    defImg(item, index) {
      let img = item.srcElement;
      switch (this.newsTrends[index].ArticleTypeID) {
        case "1":
          img.src = require("../../../assets/images/news/zxzc.png");
          break;
        case "2":
          img.src = require("../../../assets/images/news/hyxw.png");
          break;
        case "3":
          img.src = require("../../../assets/images/news/qyzz.png");
          break;
        case "4":
          img.src = require("../../../assets/images/news/jjjl.png");
          break;
        default:
          img.src = require("../../../assets/images/news/zscq.png");
          break;
      }
      img.onerror = null; //防止闪图
    },
  },
  beforeDestroy(){
    // window.removeEventListener('scroll', this.scrollHandle, true); //销毁滚动监听
  }
};
</script>

<style lang="scss" scoped>
  @media (min-width:1201px){
    @import '@/components/content/home/HomeNews.scss';
  }
  @media (max-width:1200px){
    @import '@/components/content/home/HomeNews_phone.scss';
  }


</style>
