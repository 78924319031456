<!--Author: zjy   
	Date(该日期为最后修改日期): 2020.12.21
	Description:首页之专业服务平台模块中的轮播图
	History: 暂无
-->
<template>
  <div id="home-section-swiper">
    <div class="block">
      <el-carousel trigger="click" height="200px" arrow="never">
        <el-carousel-item v-for="(item, index) in picList" :key="index">
          <img class="imgswipe" :src="imgUrlFront+item.ImgUrl" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeSectionSwiper",
  data() {
    return {
      picList: [],
      imgUrlFront: "", //图片前缀url
    };
  },
  created(){
    this.init()
  },
  methods:{
    init(){
      this.imgUrlFront = window.sessionStorage.getItem("apiImgUrl");
      this.$axios('/API/Get_Banner',{
        Version:'',
        Status: 3,
        BelongAreaID:2
      }).then( res => {
        this.picList = res.data
        // this.picList = res.data     
      })
    },
  }

};
</script>

<style lang="scss">

 @media(min-width: 1201px){
      #home-section-swiper {
      display: flex;
      justify-content: center;

    }
    #home-section-swiper .block {
      width: 1200px;
    }
    #home-section-swiper .el-carousel__container {
      height: 200px;
      width: 100%;
    }
    #home-section-swiper .block ul {
      margin: 0 auto;
    }
    #home-section-swiper .block ul li {
      margin: 0 10px 20px 10px;
    }
    #home-section-swiper .el-carousel__indicator--horizontal {
      padding: 0;
    }
    #home-section-swiper .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      margin: 0;
    }
    #home-section-swiper .el-carousel__button {
      display: block;
      width: 10px;
      height: 10px;
      background-color: #fff;
      border: none;
      outline: 0;
      padding: 0;
      margin: 0;
      cursor: pointer;
      transition: 0.3s;
      border-radius: 50%;
    }
    .imgswipe{
    width: 100%;
      height: 100%;
      border-radius: 10px;
    }
 }
 @media(max-width: 1200px){
   #home-section-swiper{
     width: 100%;
    .block {
      width: 100%;
      ul{
        li{
          margin: 0 10px 21px 10px !important;
        }
      }
    }
    .el-carousel__container {
          // max-height: 120px;
          .el-carousel__item{
            .imgswipe{
              max-width: 100%;
              max-height: 100%; 
            }
            // @media (min-width:750px){
            //   .imgswipe{
            //     width: 100%;
            //     max-height: 180px;
            //     height: 100%;
            //   }
            // }
            // @media(max-width:750px){
            //   .imgswipe{
            //     width: 100%;
            //     max-height: 80px;
            //     height: 100%;
            //   }
            // }
            
          }
      
    }
   
   }
 }
</style>
