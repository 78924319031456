<!--Author: zjy
	Date(该日期为最后修改日期): 2020.12.21
	Description:首页之专业服务平台模块
	History: 暂无
-->
<template>
  <div id="section_box">
    <div class="section">
      <div class="Enterprise_Service_Platform">
        <!-- animate__animated animate__bounceInUp -->
        <div class="esp_content">
          <h2 class="">专业的企业服务平台</h2>
          <p>
            支持企业知识产权，AAA认证，ISO项目，科技项目，双软认证，资质项目等多个认证项目相关咨询服务，查询服务，业务办理等
          </p>
        </div>
        <div class="esp_list">
          <div
            class="esp_list_item"
            v-for="(item, index) in espList"
            :key="index"
			@click="clickItem(index)"
          >
            <img :src="imgUrlFront + item.ServiceTypeImgUrl2" />
            
            <p style="margin-top: 8px">{{ item.ServiceTypeName }}</p>
          </div>
        </div>
        <HomeSectionSwiper></HomeSectionSwiper>
      </div>
    </div>
  </div>
</template>

<script>
import HomeSectionSwiper from "./HomeSectionSwiper.vue";

export default {
  name: "HomeSection",
  data() {
    return {
      imgUrlFront: "", //图片前缀url
      espList: [], //专业企业服务平台列表
    };
  },
  created() {
    this.imgUrlFront = window.sessionStorage.getItem("apiImgUrl");
    //传入参数
    let requestParams = { Status: 3, TopNum: 6, IsShowIndex: "1" };
    //请求服务平台接口
    this.$axios("API/Get_ServiceType", requestParams)
      .then((res) => {
        this.espList = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  components: {
    HomeSectionSwiper,
  },
  methods: {
    clickItem(index){
		// this.$router.push('home/detail/' + this.espList[index].id)
		this.$router.push({
			path:'/product',
			query: {
				FLnkID: this.espList[index].FLnkID,
				OrderID: this.espList[index].OrderID
			}
		})
	}
  },
};
</script>

<style lang="scss">
@import "@/pages/publicAnimation.scss";
@media(min-width:1201px) {
  @import '@/components/content/home/HomeSection.scss';
}
@media (max-width: 1200px) {
   @import '@/components/content/home/HomeSection_phone.scss';
}

</style>
