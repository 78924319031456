<!--Author: zjy   
	Date(该日期为最后修改日期): 2021.1.8
	Description:首页之常见问题模块
	History: 暂无
-->
<template>
	<div id="section3_box">
		<div class="Question">
			<div class="question_body">
				<div class="question_left">
					<div class="fb_topimg">
						<img src="~/assets/images/home/question_title_2.png" >
					</div>
					<div class="fb_insertimg">
						<img src="~/assets/images/home/img-question.png" >
					</div>
					<ul v-for="(item,index) in questionList" :key="index" >
						<h5>{{item.Question}}</h5>
						<li :title="item.Answer">{{item.Answer}}</li>
					</ul>
					<!-- <div class="findMoreQuestion">
						查看更多
					</div> -->
				</div>
				<div class="question_right">
					<div class="fb_topimg">
						<img src="~/assets/images/home/question_title_1.png" >
					</div>
					<home-question-form></home-question-form>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import HomeQuestionForm from './HomeQuestionForm.vue'
export default{
	name:'HomeQuestion',
	data(){
		return {
			questionList:[],			//常见问题首页展示
			
			
		}
	},
	components:{
		HomeQuestionForm
	},
	created() {
		let requestParams = {'Status':3,'TopNum':2}
		this.$axios('API/Get_CommonQA',requestParams).then(res => {
			this.questionList = res.data
		}).catch( err => { console.log(err) })
	},
	
}
</script>

<style lang="scss" scoped>
	@media(min-width: 1201px){
		@import '@/components/content/home/HomeQuestion.scss';
	}
	@media(max-width: 1200px){
		@import '@/components/content/home/HomeQuestion_phone.scss';
	}
</style>
