<!--Author: zjy   
	Date(该日期为最后修改日期): 2020.12.21
	Description:首页,用于展示
	History: 暂无
-->
<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <swiper :picList="picList"></swiper>
    <home-section></home-section>
    <home-product></home-product>
    <home-news></home-news>
    <home-question></home-question>
    <footer-page></footer-page>
  </div>
</template>

<script>
import swiper from "@/components/common/swiper/Swiper.vue";
import footerPage from "@/components/common/footer/footerPage.vue";
import HomeSection from "@/components/content/home/HomeSection.vue";
import HomeNews from "@/components/content/home/HomeNews.vue";
import HomeProduct from "@/components/content/home/HomeProduct.vue";
import HomeQuestion from "@/components/content/home/HomeQuestion.vue";

export default {
  name: "Home",
  data() {
    return {
      picList: [], //轮播图数据
      fullscreenLoading: true,
      imgUrlFront: "",
      // certificationShow:true,//未认证时弹出框首次登录显示
    };
  },
  computed:{
    userinfo(){
      return this.$store.state.userinfo
    },
  },
  components: {
    swiper,
    footerPage,
    HomeSection,
    HomeNews,
    HomeProduct,
    HomeQuestion,
  },
  created() {
    this.$store.commit("updateIsActiveIndex", 0);
    //请求轮播图接口
    const requestParams = { Status: 3, BelongAreaID: "1" };
    this.$axios("API/Get_Banner", requestParams)
      .then((res) => {
        this.picList = res.data;
        this.fullscreenLoading = false;
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(this.userinfo)
    console.log(sessionStorage.getItem('homeDialog'))
    // if(this.userinfo != null  && sessionStorage.getItem('homeDialog') == null){
    //   console.log('进来了')
    //   console.log(this.userinfo.Status)
    //   if(this.userinfo.Status == 3){
    //     this.$notify({
    //       title: '提示',
    //       type: 'warning',
    //       dangerouslyUseHTMLString: true,
    //       // message: '请尽快进行企业认证<span style="color: #3a86ff;margin-left: 3px;cursor: pointer;" >去认证>></span>',
    //       message: '请尽快进行企业认证',
    //       duration: 0,
    //       offset: 100,
    //       onClick:() => {
    //         console.log(1)
    //       }
    //     });
    //   }
    // }
      
  },
  mounted(){
  },
  methods:{
    openCertification(){
      console.log(1)
    }
  }
};
</script>

<style>

</style>
