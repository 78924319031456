<!--Author: zjy   
	Date(该日期为最后修改日期): 2020.12.21
	Description:首页之热门产品模块
	History: 暂无
-->
<template>
  <div id="section2_box">
    <div class="section">
      <div class="hot_content">
        <h2>热门产品</h2>
        <p class="hot_contintro">
          知识产权、AAA认证、ISO项目、科技项目、双软认证、资质项目
        </p>
        <p class="moreProduct" @click="toproduct">更多产品></p>
        <div class="hot_product">
          <div
            class="hotmain"
            v-for="(itembox, indexbox) in productList"
            :key="indexbox"
          >
            <div class="mainleft" :class="'mainleft'+indexbox">
              <div class="title_image">
                <img :src="imgUrlFront+itembox.stypeImgUrl" />
              </div>
              <div class="title">{{itembox.stypeName}}</div>
            </div>
            <div class="mainright">
              <div
                class="product_info draw meet"
                v-for="(item, index) in itembox.stypeList.slice(0,6)"
                :key="index"
                @click="toProductPage(indexbox, index)"
                title="查看详情"
              > 
                <div class="productpicbox">
                  <img class="productpic" :src="imgUrlFront+item.ListImageUrl" alt="找不到资源">
                </div>
                <h3>{{ item.ServiceItemName }}</h3>
                <p>{{ item.Description }}</p>
                <div class="product_tag">
                  <span class="product_tag_1">成功率高</span>
                  <span class="product_tag_1">价格低</span>
                  <span class="product_tag_1">服务质量好</span>
                  <span class="product_tag_1">效率高</span>
                </div>
                <div class="product_price">
                  ￥<span style="font-size: 22px">{{
                    (item.StartPrice / 100).toFixed(2).split(".")[0]
                  }}</span>.
                  <span style="font-size: 16px">{{
                    (item.StartPrice / 100).toFixed(2).split(".")[1]
                  }}</span
                  >起
                </div>
                <div
                  class="product_detail_btn"
                  id="product_detail_btn"
                >
                  点击查看
                </div>
                <div class="now_buy_btn">
                  查看<br>详情
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toFix } from "@/utils/utils.js";
export default {
  name: "HomeProduct",
  data() {
    return {
     imgUrlFront: "", //图片前缀url
      productList: [], //产品展示列表
      
    };
  },
  created() {
      this.imgUrlFront = window.sessionStorage.getItem("apiImgUrl");
      let requestParams = { Status: 3, TopNum: 6, IsShowIndex: "1" };
      //请求服务平台接口
      this.$axios("API/Get_ServiceType", requestParams).then((res) => {
          res.data.forEach((item,index) => {
            if(index < 2){
              let obj = {}
              let request = {
                Version: "",
                Status: 3,
                ServiceTypeGuid: item.FLnkID,
              }
              this.$axios("API/Get_ServiceItem", request).then((res) => {
                obj.stypeImgUrl = item.ServiceTypeImgUrl
                obj.stypeName = item.ServiceTypeName
                obj.stypeList = res.data

                this.productList.push(obj)
              })
            }
          })
      })
       
  },
  methods: {
    toProductPage(indexbox, index) {
      this.$router.push({
        path: "/prodcut/productdetail",
        query: {
          // productobj: JSON.stringify(this.productList[indexbox].stypeList[index]),
          flnkid : this.productList[indexbox].stypeList[index].FLnkID
        },
      });
    },
    toproduct() {
      this.$router.push({
        path: "/product",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @media( min-width: 1201px){
    @import '@/components/content/home/HomeProduct.scss';
  }
  @media( max-width: 1200px){
    .now_buy_btn{
      display: none
    }
    @import '@/components/content/home/HomeProduct_phone.scss';    
  }
</style>
